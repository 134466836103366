<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col>
                <v-switch
                  v-model="user_data.status"
                  @click="updateInvoice()"
                  inset
                  :label="'Zapłacona: ' + (user_data.status?'TAK':'NIE')"
                  class="classic_switch"
                ></v-switch>
              </v-col>
              <v-col class="flex_end">
                <v-btn
                  color="secondary_dark"
                  dark
                  depressed
                  tile
                  :to="'/faktura/edytuj/'+id"
                  v-if="$store.state.user_permissions.invoice_update"
                >
                  Edytuj fakturę
                </v-btn>
              </v-col>
            </v-row>

        </v-card>

        <v-card>
          <v-card-title>Dane faktury</v-card-title>
          <v-card-text>
            <p>Numer faktury: {{user_data.number_invoice}}</p>
            <p>Kwota brutto: {{user_data.price_brutto}} zł</p>
            <p>Kwota netto: {{user_data.price}} zł</p>
            <p>Data wystawienia: {{user_data.date_create}}</p>
            <p>Termin zapłaty: {{user_data.date_invoice}}</p>
          </v-card-text>
        </v-card>
        
        <v-card class="card_section">
          <v-card-title>Dane firmy</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <h3>Firma:</h3>
                  <p>{{user_data.user_assign.full_name}}</p>
                  <h3>Adres:</h3>
                  <p>{{user_data.user_assign.address}} <br>
                    {{user_data.user_assign.zip_code}} {{user_data.city}}
                  </p>
                  <h3>NIP: {{user_data.user_assign.nip}}</h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="card_section">
          <v-card-title>Załącznik</v-card-title>
          <v-card-text>
            <v-btn
              @click="openFile(user_data.files.id,user_data.files.name)"
              color="grey"
              class="white--text"
            >
              {{user_data.files.name}}
            </v-btn>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
    
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista faktur',
        disabled: false,
        to: '/faktury',
      },
      {
        text: 'Faktura',
        disabled: true,
        to: '/faktura',
      },
    ],

    id: 0,

    // attached_files: 0,
    number_invoice: '68/2/2021',
    user_data: {
      status: false,
      address: 'Ulica 21',
      city: 'Rzeszów',
      full_name: 'Andrzej Maślak z ZOO',
      nip: '123456789',
      zip_code: '35-000',
      user_assign: {},
      files: {}
    },
    
    search: ''
  }),
  methods: {
    check() {
      console.log(this.user_data);
    },
    updateInvoice(){
      this.$store.commit('loader');

      let formData = new FormData();
      console.log(this.user_data.status);
      formData.append("status", this.user_data.status);

      this.$axios({url: this.$store.state.api +'/invoice/'+this.id+'?_method=PUT', data: formData, method: 'POST' })
        .then(resp => {
          this.$store.commit("snackbar", {
            text: "Zaktualizowano fakturę",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    openFile(id,file_name){
      this.$store.dispatch('download_file', { id: id,name: file_name });
    }
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/invoice/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        console.log(resp.data);
        this.user_data = resp.data;
        // this.attached_files = resp.data.files;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>